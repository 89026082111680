@tailwind base;
@tailwind components;
@tailwind utilities;

.chat-message {
    /* Font-family: STXihei, "Chinese is fine and black", "Microsoft YaHei", "Microsoft YaHei" */
    /* Font-family: Georgia, SimSun, “ Song style ” */
    /* Font-family: Helvetica, Tahoma, Arial, STXihei, “ Chinese is fine and black ”, “Microsoft YaHei”, “ Microsoft YaHei ”, sans-serif */
    font-family: 'Hiragino Kaku Gothic Pro', 'WenQuanYi Zen Hei', '微軟正黑體',
        '蘋果儷中黑', Helvetica, Arial, sans-serif;
}

.chat-background-a {
    background-color: lightgrey;
    background-image: url(./assets/leaves-gc3725069d_640.png);
    background-size: 80px;
    opacity: 15%;
}

.chat-background-b {
    background-color: lightgrey;
    background-image: url(./assets/cosmos-gdfe7730fc_640.png);
    background-size: 120px;
    opacity: 30%;
}

.chat-background-c {
    background-color: lightgrey;
    background-image: url(./assets/flowers-g000d40dae_640.png);
    background-size: 50px;
    opacity: 40%;
}

.chat-background-d {
    background-color: lightgray;
    background-image: url(./assets/leaves-g7d895a0d3_640.png);
    background-size: 100px;
    opacity: 20%;
}

.chat-background-e {
    background-color: lightgray;
    background-image: url(./assets/flowers-ga560bab60_640.png);
    background-size: 100px;
    opacity: 20%;
}

.chat-background-f {
    background-color: lightgray;
    background-image: url(./assets/plum-g12a68c27e_640.png);
    background-size: 100px;
    opacity: 20%;
}

.chat-background-g {
    background-color: lightgray;
    background-image: url(./assets/seamless-pattern-g48ecb0ed5_640.png);
    background-size: 50px;
    opacity: 20%;
}

.chat-background-h {
    background-color: lightgray;
    background-image: url(./assets/set-gb31a9fce6_640.jpg);
    background-size: 100px;
    opacity: 20%;
}

.chat-background-i {
    background-color: lightgray;
    background-image: url(./assets/wave-g785161200_640.png);
    background-size: 100px;
    opacity: 20%;
}

.chat-background-j {
    background-color: lightgray;
    background-image: url(./assets/waves-g2e87fa0a1_640.png);
    background-size: 200px;
    opacity: 25%;
}

.chat-background-k {
    background-color: lightgray;
    background-image: url(./assets/white-g784e0fc1b_640.png);
    background-size: 100px;
    opacity: 20%;
}

.wave-span {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    height: 30px;
    width: 7px;
    margin-right: 10px;
}

.wave-span:nth-child(1) {
    animation-delay: 0.1s;
}

.wave-span:nth-child(2) {
    animation-delay: 0.2s;
}

.wave-span:nth-child(3) {
    animation-delay: 0.3s;
}

.wave-span:nth-child(4) {
    animation-delay: 0.4s;
}

.wave-span:nth-child(5) {
    animation-delay: 0.5s;
}

.wave-span:nth-child(6) {
    animation-delay: 0.6s;
}

.wave-span:nth-child(7) {
    animation-delay: 0.7s;
}

.wave-span:nth-child(8) {
    animation-delay: 0.8s;
}

.wave-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
}

.circle {
    @apply w-4 h-4 my-12 mx-1 rounded-full;
}

.circle:nth-child(1) {
    animation-delay: 0s;
}

.circle:nth-child(2) {
    animation-delay: 0.1s;
}

.circle:nth-child(3) {
    animation-delay: 0.2s;
}

.circle:nth-child(4) {
    animation-delay: 0.3s;
}

.circle:nth-child(5) {
    animation-delay: 0.4s;
}

.chat--disapear {
    display: none;
}

.chat--disapear.active {
    animation: scale-display 0.5s;
    display: block;
}

.chat--disapear .out {
    animation: scale-display--reversed 0.3s;
    animation-fill-mode: forwards;
    display: block;
}

@keyframes scale-display {
    0% {
        opacity: 0;
        transform: scale(0);
        -webkit-transform: scale(0);
    }

    100% {
        opacity: 1;
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

@keyframes scale-display--reversed {
    0% {
        display: inline-flex;
        opacity: 1;
        transform: scale(1);
        -webkit-transform: scale(1);
    }
    99% {
        display: inline-flex;
        opacity: 0;
        transform: scale(0);
        -webkit-transform: scale(0);
    }
    100% {
        display: none;
        opacity: 0;
        transform: scale(0);
        -webkit-transform: scale(0);
    }
}

@layer utilities {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}

strong {
    font-weight: 700;
}

.queueLoader {
    margin: auto;
    border-radius: 50%;
    border-top: 5px solid;
    width: 120px;
    height: 120px;
    animation: spinner 4s linear infinite;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
